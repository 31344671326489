import React from "react";
import ModalImage from "react-modal-image";
import Page from "../../components/layouts/page";
import PoSystem1 from "../../images/po-system1.png";
import PoSystem2 from "../../images/po-system2.png";
import PoSystem3 from "../../images/po-system3.png";

export default function PurchaseOrderManagementSystem() {
    return (
        <Page>
            <section className="section bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6"
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-duration="700"
                            data-sal-easing="ease">
                            <div className="skills-img me-0 me-lg-5">
                                <ModalImage small={PoSystem1} large={PoSystem1} alt="PO System" className="img-fluid rounded shadow-sm"/>
                                <div className="mt-3 clearfix">
                                    <div className="w-50 float-start pe-2">
                                        <ModalImage small={PoSystem2} large={PoSystem2} alt="PO System" className="img-fluid rounded shadow-sm" />
                                    </div>
                                    <div className="w-50 float-end ps-2">
                                        <ModalImage small={PoSystem3} large={PoSystem3} alt="PO System" className="img-fluid rounded shadow-sm" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="section-head mt-5 mt-lg-0"
                                data-sal="slide-up"
                                data-sal-delay="200"
                                data-sal-duration="600"
                                data-sal-easing="ease">
                                <span className="pre-title small position-relative fw-bold text-dark text-uppercase">Web Application</span>
                                <h2 className="section-title text-dark mt-3">Purchase Order Management System</h2>
                                <p className="section-subtitle mt-3">Manage and maintain list of purchase orders.</p>
                                <ul className="check-list list-unstyled mb-0">
                                    <li>Add, update or delete purchase orders.</li>
                                    <li>Add or update surcharges and deductions.</li>
                                    <li>Export purchase order into a PDF.</li>
                                </ul>
                            </div>
                            <div className="skills-content mt-2 me-0 me-md-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="600"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">CodeIgniter</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">100%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="700"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">Vanilla JS</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">70%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="800"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">jQuery</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">30%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '30%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                        <div className="mt-4"
                                            data-sal="slide-left"
                                            data-sal-delay="200"
                                            data-sal-duration="900"
                                            data-sal-easing="ease">
                                            <div className="clearfix">
                                                <span className="d-block w-50 float-start fw-bold small">MySQL</span>
                                                <span className="d-block w-50 float-end fw-bold small text-end">100%</span>
                                            </div>
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-warning text-dark" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>
    );
}
